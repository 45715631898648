<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="predictionData"
      item-key="_id"
      :items-per-page="5"
      show-select
      single-select
      show-expand
      single-expand
      v-model="selectedData"
      class="elevation-3"
      :loading="tableLoading"
      loading-text="ロード中。お待ちください"
    >
      <template v-slot:[`item.updated`]="{ item }">
        <div>{{ item.updated | dateFilter }}</div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <real-prediction
            :data="item"
            class="my-6 elevation-3"
          ></real-prediction>
        </td>
      </template>
      <!-- <template v-slot:item.memo="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="d-inline-block text-truncate"
                  style="max-width: 150px;"
                  >{{ item.memo }}</span
                >
              </template>
              <div>{{ item.memo }}</div>
            </v-tooltip>
          </template> -->
    </v-data-table>

    <v-layout class="mt-6">
      <v-flex></v-flex>
    </v-layout>
  </div>
</template>

<script>
import firebase from "firebase";
import realPrediction from "./realPrediction.vue";

export default {
  components: {
    realPrediction,
  },
  data: () => ({
    headers: [
      { text: "予診票名", align: "left", value: "title" },
      { text: "更新日", align: "left", value: "updated" },
      //   { text: "メモ", align: "left", value: "memo" },
      { text: "", align: "right", value: "data-table-expand" },
    ],
    predictionData: [],
    selectedData: [],
    tableLoading: false,
  }),
  created() {
    this.tableLoading = true;
    firebase
      .firestore()
      .collection("pre_exam_data")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const addData = doc.data();
          addData._id = doc.id;
          this.predictionData.push(addData);
        });
        this.tableLoading = false;
      });
  },
  methods: {},
  watch: {
    selectedData: {
      handler(val) {
        this.$emit("selected-data", val);
      },
      deep: true,
    },
  },
  filters: {
    dateFilter(dateArg) {
      const date = dateArg.toDate();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      return `${year}/${`00${month}`.slice(-2)}/${`00${day}`.slice(
        -2
      )} ${`00${hour}`.slice(-2)}:${`00${minute}`.slice(-2)}`;
    },
  },
};
</script>
