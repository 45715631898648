<template>
  <div>
    <h2 class="pageTitle pt-5 mb-8">デフォルト予診票一覧</h2>
    <prediction-list
      @selected-data="dataSelected"
      v-if="showAll"
    ></prediction-list>
    <v-row class="ml-6">
      <p class="pl-12 pt-6 caption">↑チェックしたファイル</p>
      <v-col cols="6">
        <v-select
          v-bind:items="todoList"
          v-model="whatdo"
          label="選択して下さい"
          solo
          return-object
          style="display: inline"
        ></v-select>
      </v-col>
      <p class="pt-6 caption">する</p>
      <v-btn
        class="ml-5 mt-4 blue accent-1"
        :disabled="!whatdo || !selectedData[0]"
        @click="runFunction"
        >実行</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        class="mt-3"
        style="margin-right: 60px"
        color="primary"
        large
        @click="$router.push({ name: 'makePrediction' })"
        >新規作成</v-btn
      >
    </v-row>
    <v-card
      v-if="targetText"
      class="text-center mx-12 blue lighten-5 mb-12 pb-12"
    >
      <v-card-title>{{ targetText }}</v-card-title>
      <vue-qrcode :value="targetText" :options="option" tag="img"></vue-qrcode>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import predictionList from "../components/predictionList.vue";

export default {
  components: {
    predictionList,
    VueQrcode,
  },
  data: () => ({
    selectedData: [],
    todoList: [
      { text: "を編集", disabled: false },
      { text: "を削除", disabled: false },
      //   { text: "のURLを生成" },
    ],
    whatdo: "",
    showAll: true,
    inputText: "",
    targetText: "",
    option: {
      errorCorrectionLevel: "M",
      maskPattern: 0,
      margin: 10,
      scale: 2,
      width: 300,
      color: {
        dark: "#000000FF",
        light: "#FFFFFFFF",
      },
    },
  }),
  methods: {
    dataSelected(data) {
      this.selectedData = data;
    },
    runFunction() {
      if (this.selectedData.length > 0) {
        switch (this.whatdo.text) {
          case "を編集":
            this.editData();
            break;
          case "を削除":
            this.deleteData();
            break;
          case "のURLを生成":
            this.makeURL();
            break;
          default:
            break;
        }
      }
    },
    editData() {
      const id = this.selectedData[0]._id;
      this.$router.push({ name: "makePrediction", query: { id } });
    },
    deleteData() {
      const f = window.confirm(
        `予診票「${this.selectedData[0].title}」を削除します。よろしいですか？`
      );
      if (f) {
        firebase
          .firestore()
          .collection("pre_exam_data")
          .doc(this.selectedData[0]._id)
          .delete()
          .then(() => {
            this.showAll = false;
            this.$nextTick(() => {
              this.showAll = true;
            });
          });
      }
    },
    makeURL() {
      this.generate();
    },
    generate() {
      this.targetText = `https://small.nenaiho.net/adela/dist/#/answerQuestions?id=${this.selectedData[0]._id}`;
    },
  },
  watch: {},
  filters: {},
};
</script>
<style>
.pageTitle {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
</style>
